body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

* { 
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
   box-sizing: border-box; 
}

@media (max-width: 1500px) {
  html {
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 18px;
  }
}

html {
  font-size: 16px;
}
@media (max-width: 700px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 450px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 350px) {
  html {
    font-size: 12px;
  }
}



@layer components {
  .btn {
    align-items: center;
    border-color: transparent;
    border-radius: 0.25rem;
    border-width: 1px;
    display: inline-flex;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.5rem 0.75rem;
    pointer-events: auto;
  }

  .btn-neutral {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    border-color: rgba(0, 0, 0, 0.1);
    border-width: 1px;
    color: rgba(64, 65, 79, var(--tw-text-opacity));
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .btn-neutral:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 236, 241, var(--tw-bg-opacity));
  }

  .dark .btn-neutral {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(52, 53, 65, var(--tw-bg-opacity));
    border-color: rgba(86, 88, 105, var(--tw-border-opacity));
    color: rgba(217, 217, 227, var(--tw-text-opacity));
  }

  .dark .btn-neutral:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 65, 79, var(--tw-bg-opacity));
  }

  .btn-dark {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(52, 53, 65, var(--tw-bg-opacity));
    border-color: rgba(86, 88, 105, var(--tw-border-opacity));
    border-width: 1px;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .btn-primary {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(16, 163, 127, var(--tw-bg-opacity));
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .btn-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(26, 127, 100, var(--tw-bg-opacity));
  }

  .btn-small {
    padding: 0.25rem 0.5rem;
  }

  button.scroll-convo {
    display: none;
  }

  .markdown ol,
  .markdown ul {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
  }

  .markdown ol li,
  .markdown ol li > p,
  .markdown ol ol,
  .markdown ol ul,
  .markdown ul li,
  .markdown ul li > p,
  .markdown ul ol,
  .markdown ul ul {
    margin: 0;
  }

  .markdown ul li:before {
    content: '•';
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-left: -1rem;
    position: absolute;
  }
}

:not(pre) > code.hljs,
:not(pre) > code[class*='language-'] {
  border-radius: 0.3em;
  white-space: normal;
}
.hljs-comment {
  color: hsla(0, 0%, 100%, 0.5);
}
.hljs-meta {
  color: hsla(0, 0%, 100%, 0.6);
}
.hljs-built_in,
.hljs-class .hljs-title {
  color: #e9950c;
}
.hljs-doctag,
.hljs-formula,
.hljs-keyword,
.hljs-literal {
  color: #2e95d3;
}
.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
  color: #00a67d;
}
.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #df3079;
}
.hljs-bullet,
.hljs-link,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: #f22c3d;
}